<template>
  <header class="page-header">
    <DialogContainer sticky />
  </header>
</template>

<script>
import DialogContainer from '@/components/dialog-container.vue';

export default {
  name: 'PageHeader',
  props: {
    theme: {
      type: String,
      default: null,
    },
  },
  components: {
    DialogContainer,
  },
};
</script>
<style lang="scss" scoped>
@import 'assets/base';

.page-header {
  z-index: 10;
  position: sticky;
  top: 0;
  border-top: 4px solid $color-primary;
}
</style>
