<template>
  <div class="layout layout--checkout">
    <PageHeader theme="small" />
    <slot />
    <DialogBackdrop />
    <client-only>
      <LoadingSpinner />
    </client-only>
    <CookieHint />
    <div id="teleport" />
    <StatusToolbarWrapper v-if="isDev" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CookieHint from '@/components/cookie-hint.vue';
import DialogBackdrop from '@/components/dialog-backdrop.vue';
import Layout from '@/mixins/layout';
import LoadingSpinner from '@/components/loading-spinner.vue';
import PageHeader from '@/components/page-header.vue';

export default {
  name: 'Checkout',
  components: {
    CookieHint,
    DialogBackdrop,
    LoadingSpinner,
    PageHeader,
    StatusToolbarWrapper: () => import('@/components/status-toolbar-wrapper.vue'),
  },
  mixins: [Layout],
  data() {
    return {
      isDev: !this.$config.public.mode.isProduction,
    };
  },
  computed: {
    ...mapGetters('cart', ['cartIsEmpty', 'cartInitialized']),
    isCartEmpty() {
      return this.cartIsEmpty && this.cartInitialized;
    },
  },
  watch: {
    isCartEmpty: {
      handler(value) {
        if (value) {
          this.$router.replace('/shop/warenkorb');
        }
      },
      immediate: true,
    },
  },
};
</script>
